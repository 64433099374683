<template>
  <b-form class="my-3" @submit.stop.prevent="onSubmit" v-if="viewApp">
    <h2 class="text-center">WEEKLY MANUAL & AUTOMATIC LESSONS</h2>
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="form-group">
          <label for="firstName">First name</label>
          <b-form-input
            v-model="form.firstname"
            id="firstName"
            maxlength="255"
            placeholder="Enter first name"
            :state="validateState('firstname')"
          ></b-form-input>
          <b-form-invalid-feedback>First name required</b-form-invalid-feedback>
          <small class="form-text text-muted">
            Please contact us if this is incorrect
          </small>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="form-group">
          <label for="lastName">Last name</label>
          <b-form-input
            v-model="form.lastname"
            id="lastName"
            maxlength="255"
            placeholder="Enter last name"
            :state="validateState('lastname')"
          ></b-form-input>
          <b-form-invalid-feedback>Last name required</b-form-invalid-feedback>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col form-group">
        <label for="drivingLicense">Driving license number</label>
        <b-form-input
          v-model="form.drivingLicense"
          :state="validateState('drivingLicense')"
          type="text"
          id="drivingLicense"
          placeholder="Enter driving license number"
        ></b-form-input>
        <b-form-invalid-feedback
          >Driving license must be 16 digits</b-form-invalid-feedback
        >
        <small class="form-text text-muted">
          This is a 16-character number that's unique to you. It appears on your
          photocard driving licence underneath the licence's expiry date. You
          can also go visit GOV.UK
        </small>
      </div>
    </div>

    <div class="form-row">
      <div class="col-12 col-sm-6">
        <div class="form-group">
          <label for="licenseExpiry">Driving license expiry date</label>
          <b-form-input
            v-model="form.licenseExpiry"
            placeholder="Enter driving license expiry"
            :state="validateState('licenseExpiry')"
            type="date"
          ></b-form-input>
          <b-form-invalid-feedback>Date required</b-form-invalid-feedback>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="phone">Phone number</label>
      <b-form-input
        v-model="form.phone"
        type="number"
        id="phone"
        placeholder="Enter phone number"
        :state="validateState('phone')"
      ></b-form-input>
      <b-form-invalid-feedback>Phone number required</b-form-invalid-feedback>
    </div>

    <div class="form-group">
      <b-alert v-if="form.glasses === 'yes'" show variant="warning"
        >Please bring your glasses or contact lenses to the lesson</b-alert
      >
      <label> Do you wear glasses or contacts? </label>
      <div>
        <b-button-group>
          <b-form-radio-group
            v-model="form.glasses"
            :options="options"
            buttons
            :state="validateState('glasses')"
          ></b-form-radio-group>
        </b-button-group>
      </div>
      <b-form-invalid-feedback :state="validateState('glasses')"
        >Choose yes or no</b-form-invalid-feedback
      >
    </div>

    <div class="form-group">
      <label
        >Have you booked or completed a theory test in the last two
        years?</label
      >
      <div>
        <b-button-group>
          <b-form-radio-group
            v-model="form.theoryTest"
            :options="options"
            buttons
            :state="validateState('theoryTest')"
            v-on:change="resetTheoryTestFields"
          ></b-form-radio-group>
        </b-button-group>
      </div>
    </div>

    <div class="form-row" v-if="form.theoryTest === 'yes'">
      <div class="col form-group">
        <label for="theoryTestOutcome"
          >What was the outcome of your theory test?</label
        >
        <b-form-select
          v-model="form.theoryTestOutcome"
          :options="theoryTestOutcomeOptions"
          v-on:change="resetTheoryTestFields"
        ></b-form-select>
        <small class="form-text text-muted">
          Add date calendar to input date of above (pass, fail , booked)
        </small>
        <b-form-invalid-feedback :state="validateState('theoryTestOutcome')"
          >Choose pass, fail or booked</b-form-invalid-feedback
        >
      </div>
    </div>

    <div
      class="form-row"
      v-if="form.theoryTestOutcome !== null && form.theoryTest === 'yes'"
    >
      <div class="col-12 col-sm-6">
        <div class="form-group">
          <label for="theoryOutcomeDate"
            >Date of {{ form.theoryTestOutcome | capitalize }}</label
          >
          <b-form-input
            v-model="form.theoryOutcomeDate"
            placeholder="Enter driving license expiry"
            :state="validateState('theoryOutcomeDate')"
            type="date"
          ></b-form-input>
          <b-form-invalid-feedback>Date required</b-form-invalid-feedback>
        </div>
      </div>
    </div>

    <div
      class="form-group"
      v-if="form.theoryTestOutcome === 'pass' && form.theoryTest === 'yes'"
    >
      <label for="phone">Theory test certificate number</label>
      <b-form-input
        v-model="form.theoryCertificate"
        type="number"
        id="phone"
        placeholder="Theory test certificate number"
        :state="validateState('theoryCertificate')"
      ></b-form-input>
      <small class="form-text text-muted">
        Cant find your certificate? Go to
        <a
          href="https://www.gov.uk/find-theory-test-pass-number"
          target="_blank"
          >www.gov.uk/find-theory-test-pass-number</a
        >
      </small>
      <b-form-invalid-feedback
        >Theory test certificate number required</b-form-invalid-feedback
      >
    </div>

    <div class="form-row">
      <div class="col form-group">
        <label for="hearAboutUs">How did you hear about us?</label>
        <b-form-select
          v-model="form.hearAboutUs"
          :options="hearAboutUsOptions"
        ></b-form-select>
      </div>
    </div>

    <b-form-group>
      <b-form-checkbox
        id="terms"
        v-model="form.terms"
        name="terms"
        :state="validateState('terms')"
        trim
      >
        <label class="form-check-label" style="font-size: smaller" for="terms"
          >Please take care to read through the following
          <a
            href="https://chilleddrivingtuition.co.uk/weekly-and-automatically-terms-conditions"
            target="_blank"
            >terms & conditions </a
          >before booking in for any weekly Manual or Automatic lessons. I
          confirm that I understand and accept the terms and conditions and
          agree in full to abide by them. I also give permission to Chilled
          Driving Tuition to use my driving licence number to reserve a test
          place in my name. I understand that this may be used to reserve a
          place for myself or another pupil awaiting their theory test pass, but
          ultimately I will be able to use this for myself once I have paid the
          test fee to Chilled Driving Tuition and am at test standard in line
          with my instructor's guidance. Should I leave the school before my
          test, it's my responsibility to let them know so the place may be
          released.</label
        >
      </b-form-checkbox>
    </b-form-group>

    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="form-group">
          <label for="signature">Signature (digital)</label>
          <b-form-input
            v-model="form.signature"
            placeholder="Enter signature"
            :state="validateState('signature')"
          ></b-form-input>
          <b-form-invalid-feedback>Signature required</b-form-invalid-feedback>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="form-group">
          <label for="date">Date</label>
          <b-form-input
            v-model="form.date"
            placeholder="Enter signature date"
            :state="validateState('date')"
            type="date"
          ></b-form-input>
          <b-form-invalid-feedback>Date required</b-form-invalid-feedback>
        </div>
      </div>
    </div>

    <b-button type="submit" variant="primary" class="float-right"
      >Submit</b-button
    >
  </b-form>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import {
  required,
  helpers,
  sameAs,
  requiredIf,
} from "vuelidate/lib/validators";
import { getChilledDrivingWeeklyManualAutomatic } from "./../graphql/queries";
import { updateChilledDrivingWeeklyManualAutomatic } from "./../graphql/mutations";
import moment from "moment";
const validDrivingLicenseNo = (value) =>
  helpers.req(value) && value.length === 16;
export default {
  data: () => ({
    form: {
      id: null,
      firstname: null,
      lastname: null,
      drivingLicense: null,
      licenseExpiry: null,
      hearAboutUs: null,
      theoryTestOutcome: null,
      theoryOutcomeDate: null,
      theoryCertificate: null,
      phone: null,
      glasses: null,
      theoryTest: null,
      signature: null,
      date: new Date().toISOString().slice(0, 10),
      terms: false,
    },
    options: [
      { text: "Yes", value: "yes" },
      { text: "No", value: "no" },
    ],
    hearAboutUsOptions: [
      { value: "", text: "--" },
      { value: "referral", text: "Referral" },
      { value: "facebook", text: "Facebook" },
      { value: "media", text: "Social Media" },
      { value: "other", text: "Other" },
    ],
    theoryTestOutcomeOptions: [
      { value: "", text: "--" },
      { value: "pass", text: "Pass" },
      { value: "fail", text: "Fail" },
      { value: "booked", text: "Booked" },
    ],
    viewApp: false,
  }),
  created() {
    API.graphql(
      graphqlOperation(getChilledDrivingWeeklyManualAutomatic, {
        id: this.$route.params.id,
      })
    )
      .then((result) => {
        const answer = result.data.getChilledDrivingWeeklyManualAutomatic;
        if (answer !== null) {
          console.log(answer, "answer");
          this.viewApp = true;
          this.form = {
            id: answer.id,
            firstname: answer.firstName,
            lastname: answer.lastName,
            drivingLicense: answer.drivingLicenseNumber,
            licenseExpiry: answer.drivingLicenseExpiryDate,
            phone: answer.phone,
            glasses: answer.glasses,
            theoryTest: answer.theoryTest,
            theoryTestOutcome: answer.theoryTestOutcome,
            theoryCertificate: answer.theoryCertificate,
            theoryOutcomeDate: answer.theoryOutcomeDate,
            hearAboutUs: answer.hearAboutUs,
            terms: answer.terms,
            signature: answer.signature,
            date: answer.signatureDate,
          };
        }
      })
      .catch(() => {
        this.viewApp = false;
      });
  },
  validations: {
    form: {
      drivingLicense: {
        validDrivingLicenseNo,
        required,
      },
      licenseExpiry: {
        required,
      },
      theoryTest: {
        required,
      },
      theoryTestOutcome: {
        required: requiredIf(function () {
          return this.form.theoryTest === "yes";
        }),
      },
      theoryCertificate: {
        required: requiredIf(function () {
          return (
            this.form.theoryTestOutcome === "pass" &&
            this.form.theoryTest === "yes"
          );
        }),
      },
      theoryOutcomeDate: {
        required: requiredIf(function () {
          return !this.form.theoryTestOutcome && this.form.theoryTest === "yes";
        }),
      },
      firstname: {
        required,
      },
      lastname: {
        required,
      },
      phone: {
        required,
      },
      signature: {
        required,
      },
      date: {
        required,
      },
      glasses: {
        required,
      },
      terms: {
        sameAs: sameAs(() => true),
      },
    },
  },
  methods: {
    async onSubmit() {
      // console.log(this.form);
      this.$v.form.$touch();
      // console.log(this.$v.form.$anyError, "error");
      if (this.$v.form.$anyError) {
        return;
      }
      // console.log(this.form.licenseExpiry);
      const answer = {
        id: this.form.id,
        firstName: this.form.firstname,
        lastName: this.form.lastname,
        drivingLicenseNumber: this.form.drivingLicense,
        drivingLicenseExpiryDate: moment(this.form.licenseExpiry).format(
          "YYYY-MM-DD"
        ),
        phone: this.form.phone,
        glasses: this.form.glasses,
        theoryTest: this.form.theoryTest,
        theoryTestOutcome: this.form.theoryTestOutcome,
        theoryCertificate:
          this.form.theoryTestOutcome === "pass"
            ? this.form.theoryCertificate
            : null,
        theoryOutcomeDate: this.form.theoryOutcomeDate
          ? moment(this.form.theoryOutcomeDate).format("YYYY-MM-DD")
          : null,
        hearAboutUs: this.form.hearAboutUs,
        terms: this.form.terms,
        signature: this.form.signature,
        signatureDate: moment(this.form.date).format("YYYY-MM-DD"),
        complete: true,
      };
      API.graphql(
        graphqlOperation(updateChilledDrivingWeeklyManualAutomatic, {
          input: answer,
        })
      )
        .then((res) => {
          console.log(
            res.data.updateChilledDrivingWeeklyManualAutomatic,
            "res"
          );
          this.$router.push({ name: "complete" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetTheoryTestFields() {
      if (this.form.theoryTest === "no") {
        this.form.theoryTestOutcome = null;
        this.form.theoryCertificate = null;
        this.form.theoryOutcomeDate = null;
      }
      if (this.form.theoryTestOutcome !== "pass") {
        this.form.theoryCertificate = null;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateBoolean(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
  },
  computed: {
    state() {
      return Boolean(this.value);
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>
<style>
.btn.active {
  background: #00adef !important;
}
label.btn {
  background: #e0e0e0;
  border-color: #868686;
  color: #565656;
}
</style>
