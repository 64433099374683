/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createChilledDrivingWeeklyManualAutomatic = /* GraphQL */ `
  mutation CreateChilledDrivingWeeklyManualAutomatic(
    $input: CreateChilledDrivingWeeklyManualAutomaticInput!
    $condition: ModelChilledDrivingWeeklyManualAutomaticConditionInput
  ) {
    createChilledDrivingWeeklyManualAutomatic(
      input: $input
      condition: $condition
    ) {
      id
      firstName
      lastName
      drivingLicenseNumber
      drivingLicenseExpiryDate
      phone
      glasses
      theoryTest
      theoryTestOutcome
      theoryCertificate
      theoryOutcomeDate
      hearAboutUs
      terms
      signature
      signatureDate
      complete
      createdAt
      updatedAt
    }
  }
`;
export const updateChilledDrivingWeeklyManualAutomatic = /* GraphQL */ `
  mutation UpdateChilledDrivingWeeklyManualAutomatic(
    $input: UpdateChilledDrivingWeeklyManualAutomaticInput!
    $condition: ModelChilledDrivingWeeklyManualAutomaticConditionInput
  ) {
    updateChilledDrivingWeeklyManualAutomatic(
      input: $input
      condition: $condition
    ) {
      id
      firstName
      lastName
      drivingLicenseNumber
      drivingLicenseExpiryDate
      phone
      glasses
      theoryTest
      theoryTestOutcome
      theoryCertificate
      theoryOutcomeDate
      hearAboutUs
      terms
      signature
      signatureDate
      complete
      createdAt
      updatedAt
    }
  }
`;
export const deleteChilledDrivingWeeklyManualAutomatic = /* GraphQL */ `
  mutation DeleteChilledDrivingWeeklyManualAutomatic(
    $input: DeleteChilledDrivingWeeklyManualAutomaticInput!
    $condition: ModelChilledDrivingWeeklyManualAutomaticConditionInput
  ) {
    deleteChilledDrivingWeeklyManualAutomatic(
      input: $input
      condition: $condition
    ) {
      id
      firstName
      lastName
      drivingLicenseNumber
      drivingLicenseExpiryDate
      phone
      glasses
      theoryTest
      theoryTestOutcome
      theoryCertificate
      theoryOutcomeDate
      hearAboutUs
      terms
      signature
      signatureDate
      complete
      createdAt
      updatedAt
    }
  }
`;
export const createChilledDrivingAutomatic = /* GraphQL */ `
  mutation CreateChilledDrivingAutomatic(
    $input: CreateChilledDrivingAutomaticInput!
    $condition: ModelChilledDrivingAutomaticConditionInput
  ) {
    createChilledDrivingAutomatic(input: $input, condition: $condition) {
      id
      firstName
      lastName
      drivingLicenseNumber
      drivingLicenseExpiryDate
      phone
      glasses
      theoryTest
      theoryTestOutcome
      theoryCertificate
      theoryOutcomeDate
      hearAboutUs
      terms
      signature
      signatureDate
      complete
      createdAt
      updatedAt
    }
  }
`;
export const updateChilledDrivingAutomatic = /* GraphQL */ `
  mutation UpdateChilledDrivingAutomatic(
    $input: UpdateChilledDrivingAutomaticInput!
    $condition: ModelChilledDrivingAutomaticConditionInput
  ) {
    updateChilledDrivingAutomatic(input: $input, condition: $condition) {
      id
      firstName
      lastName
      drivingLicenseNumber
      drivingLicenseExpiryDate
      phone
      glasses
      theoryTest
      theoryTestOutcome
      theoryCertificate
      theoryOutcomeDate
      hearAboutUs
      terms
      signature
      signatureDate
      complete
      createdAt
      updatedAt
    }
  }
`;
export const deleteChilledDrivingAutomatic = /* GraphQL */ `
  mutation DeleteChilledDrivingAutomatic(
    $input: DeleteChilledDrivingAutomaticInput!
    $condition: ModelChilledDrivingAutomaticConditionInput
  ) {
    deleteChilledDrivingAutomatic(input: $input, condition: $condition) {
      id
      firstName
      lastName
      drivingLicenseNumber
      drivingLicenseExpiryDate
      phone
      glasses
      theoryTest
      theoryTestOutcome
      theoryCertificate
      theoryOutcomeDate
      hearAboutUs
      terms
      signature
      signatureDate
      complete
      createdAt
      updatedAt
    }
  }
`;
export const createChilledDrivingIntensive = /* GraphQL */ `
  mutation CreateChilledDrivingIntensive(
    $input: CreateChilledDrivingIntensiveInput!
    $condition: ModelChilledDrivingIntensiveConditionInput
  ) {
    createChilledDrivingIntensive(input: $input, condition: $condition) {
      id
      firstName
      lastName
      drivingLicenseNumber
      drivingLicenseExpiryDate
      phone
      glasses
      theoryTest
      theoryTestOutcome
      theoryCertificate
      theoryOutcomeDate
      hearAboutUs
      terms
      signature
      signatureDate
      complete
      createdAt
      updatedAt
    }
  }
`;
export const updateChilledDrivingIntensive = /* GraphQL */ `
  mutation UpdateChilledDrivingIntensive(
    $input: UpdateChilledDrivingIntensiveInput!
    $condition: ModelChilledDrivingIntensiveConditionInput
  ) {
    updateChilledDrivingIntensive(input: $input, condition: $condition) {
      id
      firstName
      lastName
      drivingLicenseNumber
      drivingLicenseExpiryDate
      phone
      glasses
      theoryTest
      theoryTestOutcome
      theoryCertificate
      theoryOutcomeDate
      hearAboutUs
      terms
      signature
      signatureDate
      complete
      createdAt
      updatedAt
    }
  }
`;
export const deleteChilledDrivingIntensive = /* GraphQL */ `
  mutation DeleteChilledDrivingIntensive(
    $input: DeleteChilledDrivingIntensiveInput!
    $condition: ModelChilledDrivingIntensiveConditionInput
  ) {
    deleteChilledDrivingIntensive(input: $input, condition: $condition) {
      id
      firstName
      lastName
      drivingLicenseNumber
      drivingLicenseExpiryDate
      phone
      glasses
      theoryTest
      theoryTestOutcome
      theoryCertificate
      theoryOutcomeDate
      hearAboutUs
      terms
      signature
      signatureDate
      complete
      createdAt
      updatedAt
    }
  }
`;
