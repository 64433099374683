import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/complete",
    name: "complete",
    component: () => import("../views/Complete.vue"),
  },
  {
    path: "/:id",
    name: "home",
    component: HomeView,
  },
  {
    path: "/automatic/:id",
    name: "automatic",
    component: () => import("../views/Automatic.vue"),
  },
  {
    path: "/intensive/:id",
    name: "intensive",
    component: () => import("../views/Intensive.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
