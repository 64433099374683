/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getChilledDrivingWeeklyManualAutomatic = /* GraphQL */ `
  query GetChilledDrivingWeeklyManualAutomatic($id: ID!) {
    getChilledDrivingWeeklyManualAutomatic(id: $id) {
      id
      firstName
      lastName
      drivingLicenseNumber
      drivingLicenseExpiryDate
      phone
      glasses
      theoryTest
      theoryTestOutcome
      theoryCertificate
      theoryOutcomeDate
      hearAboutUs
      terms
      signature
      signatureDate
      complete
      createdAt
      updatedAt
    }
  }
`;
export const listChilledDrivingWeeklyManualAutomatics = /* GraphQL */ `
  query ListChilledDrivingWeeklyManualAutomatics(
    $filter: ModelChilledDrivingWeeklyManualAutomaticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChilledDrivingWeeklyManualAutomatics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        drivingLicenseNumber
        drivingLicenseExpiryDate
        phone
        glasses
        theoryTest
        theoryTestOutcome
        theoryCertificate
        theoryOutcomeDate
        hearAboutUs
        terms
        signature
        signatureDate
        complete
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChilledDrivingAutomatic = /* GraphQL */ `
  query GetChilledDrivingAutomatic($id: ID!) {
    getChilledDrivingAutomatic(id: $id) {
      id
      firstName
      lastName
      drivingLicenseNumber
      drivingLicenseExpiryDate
      phone
      glasses
      theoryTest
      theoryTestOutcome
      theoryCertificate
      theoryOutcomeDate
      hearAboutUs
      terms
      signature
      signatureDate
      complete
      createdAt
      updatedAt
    }
  }
`;
export const listChilledDrivingAutomatics = /* GraphQL */ `
  query ListChilledDrivingAutomatics(
    $filter: ModelChilledDrivingAutomaticFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChilledDrivingAutomatics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        drivingLicenseNumber
        drivingLicenseExpiryDate
        phone
        glasses
        theoryTest
        theoryTestOutcome
        theoryCertificate
        theoryOutcomeDate
        hearAboutUs
        terms
        signature
        signatureDate
        complete
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChilledDrivingIntensive = /* GraphQL */ `
  query GetChilledDrivingIntensive($id: ID!) {
    getChilledDrivingIntensive(id: $id) {
      id
      firstName
      lastName
      drivingLicenseNumber
      drivingLicenseExpiryDate
      phone
      glasses
      theoryTest
      theoryTestOutcome
      theoryCertificate
      theoryOutcomeDate
      hearAboutUs
      terms
      signature
      signatureDate
      complete
      createdAt
      updatedAt
    }
  }
`;
export const listChilledDrivingIntensives = /* GraphQL */ `
  query ListChilledDrivingIntensives(
    $filter: ModelChilledDrivingIntensiveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChilledDrivingIntensives(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        drivingLicenseNumber
        drivingLicenseExpiryDate
        phone
        glasses
        theoryTest
        theoryTestOutcome
        theoryCertificate
        theoryOutcomeDate
        hearAboutUs
        terms
        signature
        signatureDate
        complete
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
