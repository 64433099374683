<template>
  <div class="home">
    <PupilIntake />
  </div>
</template>

<script>
// @ is an alias to /src
import PupilIntake from "@/components/PupilIntake.vue";

export default {
  name: "HomeView",
  components: {
    PupilIntake,
  },
};
</script>
